<!--
 * 
 * @Description: 异常订单列表  
-->
<template>
  <scroll-layout class="directConnectionPile-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              v-rbac="'order:share:charge:exception:column'"
              type="plain"
              @click="customColumns">
              自定义列
            </ykc-button>
            <ykc-button
              v-rbac="'order:share:charge:exception:export'"
              type="plain"
              @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <!--自定义列弹窗 -->
      <ykc-operation-dialog
        title="自定义列"
        show-footer
        :show.sync="showDialog"
        :before-close="onCLose"
        :before-cancel="onCLose"
        :before-confirm="beforeConfirm">
        <div slot="content">
          <el-form
            size="mini"
            label-width="80px"
            ref="customForm"
            class="customForm"
            :model="customForm"
            :rules="customRules">
            <el-form-item label="列选项" prop="checkOption">
              <ykc-checkbox
                :configSet="{
                  label: 'label',
                  value: 'value',
                }"
                :data="customData"
                v-model="customForm.checkOption"
                @change="checkboxChange"></ykc-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import { localSetItem, localGetItem, offlineExport, code } from '@/utils';
  import { orderPage, barrierPage, customerOrg } from '@/service/apis';

  export default {
    name: 'abnormalOrderList',
    components: {},
    inject: ['reload'],
    data() {
      return {
        showDialog: false,
        searchParams: {
          startChargingTimeStart: '',
          startChargingTimeEnd: '',
          userAccount: '',
          plateNumber: '',
          stationId: '',
          userCompanyId: '',
          tradeSeq: '',
          cardNo: '',
          zdlTradeSeq: '',
          orderAbnormalType: '',
          // chargingSource: '',
          chargingType: '',
          isTestStation: 0,
          isShare: 1,
        },
        userCompanyIdData: [],
        stationIdData: [],
        stopChargeReason: [], // 订单结束原因

        customForm: {
          checkOption: [0, 1, 2, 3, 4, 5, 22, 23, 24],
        },

        customData: [
          {
            value: 0,
            label: '充电单号',
          },
          {
            value: 22,
            label: '终端编码',
          },
          {
            value: 1,
            label: '充电用户',
          },
          {
            value: 2,
            label: '订单结束原因',
          },
          {
            value: 3,
            label: '异常原因',
          },
          {
            value: 4,
            label: '电站名称',
          },
          {
            value: 5,
            label: '电量（度）',
          },
          {
            value: 6,
            label: '订单金额(元)',
          },
          {
            value: 7,
            label: '归属机构',
          },
          {
            value: 8,
            label: '车牌号码',
          },
          {
            value: 9,
            label: '起始SOC',
          },
          {
            value: 10,
            label: '流量互联单号',
          },
          {
            value: 11,
            label: '桩联互联单号',
          },
          {
            value: 12,
            label: '充电卡号',
          },
          {
            value: 13,
            label: '商户',
          },
          {
            value: 14,
            label: '电桩品牌',
          },
          {
            value: 15,
            label: '电桩型号',
          },
          {
            value: 16,
            label: '电桩类型',
          },
          {
            value: 17,
            label: '启动来源',
          },
          {
            value: 18,
            label: '车辆VIN',
          },
          {
            value: 19,
            label: '订单电费',
          },
          {
            value: 20,
            label: '订单服务费',
          },
          {
            value: 21,
            label: '充电类型',
          },
        ],
        customRules: {
          checkOption: [{ required: true, message: '请至少选择一个列名称', trigger: 'blur' }],
        },
        // 列表表格
        tableTitle: '订单列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '充电单号', prop: 'tradeSeq', minWidth: '150px', id: 0 },
          { label: '终端编码', prop: 'gunCode', minWidth: '160px', id: 22 },
          {
            label: '充电用户',
            prop: 'nickName',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span>
                    {row.userAccount}({row.nickName})
                  </span>
                );
              },
            },
            id: 1,
          },
          {
            label: '订单结束原因',
            prop: 'stopChargeReason',
            minWidth: '120px',
            scopedSlots: {
              default: ({ row }) => {
                const title =
                  this.stopChargeReason?.find(o => o.code === row.stopChargeReason)?.name ?? '——';
                return <span>{title}</span>;
              },
            },
            id: 2,
          },
          {
            label: '异常原因',
            prop: 'orderAbnormalType',
            minWidth: '180px',
            scopedSlots: {
              default: ({ row }) => {
                const cls = localGetItem('dictionary')?.abnormal_type;

                if (!cls) {
                  return '一';
                }

                cls.forEach(item => {
                  if (item.code === row.orderAbnormalType) {
                    this.txt = item.name;
                  }
                });

                return <span>{this.txt}</span>;
              },
            },
            id: 3,
          },
          { label: '电站名称', prop: 'stationName', minWidth: '150px', id: 4 },
          { label: '建桩联系人', prop: 'pileName', minWidth: '120px', id: 23 },
          { label: '建桩联系人手机号', prop: 'pilePhone', minWidth: '120px', id: 24 },
          { label: '电量(度)', prop: 'chargedPower', minWidth: '120px', id: 5 },
          {
            label: '订单金额(元)',
            prop: 'totalAmount',
            minWidth: '120px',
            id: 6,
          },
          { label: '归属机构', prop: 'companyName', minWidth: '150px', id: 7 },
          { label: '车牌号码', prop: 'plateNumber', minWidth: '150px', id: 8 },
          // { label: '开始时间', prop: 'startTime', minWidth: '150px', id: 4 },
          { label: '起始SOC(%)', prop: 'startSoc', minWidth: '150px', id: 9 },
          { label: '流量互联单号', prop: 'zdlTradeSeq', minWidth: '150px', id: 10 },
          { label: '桩联互联单号', prop: 'pileTradeSeq', minWidth: '150px', id: 11 },
          { label: '充电卡号', prop: 'cardNo', minWidth: '150px', id: 12 },
          { label: '商户', prop: 'stationOperatorName', minWidth: '150px', id: 13 },
          { label: '电桩品牌', prop: 'name', minWidth: '150px', id: 14 },
          { label: '电桩型号', prop: 'modelName', minWidth: '150px', id: 15 },
          {
            label: '电桩类型',
            prop: 'pileType',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return <span>{row.pileType === 1 ? '直流' : '交流'}</span>;
              },
            },
            id: 16,
          },
          {
            label: '启动来源',
            prop: 'chargingSource',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const cls = [
                  { id: 1, name: '扫码启动' },
                  { id: 2, name: '扫码启动' },
                  { id: 3, name: '刷卡充电' },
                  { id: 4, name: '即插即充' },
                  { id: 5, name: '流量方' },
                  { id: 6, name: '预约充电' },
                  { id: 7, name: '离线卡' },
                ][Number(row.chargingSource) - 1];

                if (!cls) {
                  return '一';
                }

                return <span>{cls.name}</span>;
              },
            },
            id: 17,
          },
          { label: '车辆VIN', prop: 'carVinCode', minWidth: '150px', id: 18 },
          { label: '订单电费(元)', prop: 'chargedAmount', minWidth: '150px', id: 19 },
          { label: '订单服务费(元)', prop: 'parkedAmount', minWidth: '150px', id: 20 },
          {
            label: '充电类型',
            prop: 'chargingType',
            scopedSlots: {
              default: ({ row }) => {
                const typeMap = {
                  1: '有序充电',
                  0: '正常充电',
                };
                const type = typeMap[row.chargingType] || '——';
                return (
                  <div>
                    <span title={type}>{type}</span>
                  </div>
                );
              },
            },
            minWidth: '150px',
            id: 21,
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    created() {
      this.stopChargeReason = localGetItem('dictionary').stop_charge_reason || [];

      const customForm = localGetItem('abnormalOrderCustomForm');

      if (customForm) {
        this.customForm.checkOption = customForm;
        this.tableColumns = this.tableColumns.filter(item => {
          return customForm.includes(item.id);
        });
      } else {
        this.tableColumns = this.tableColumns.filter(item => {
          return ![6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21].includes(item.id);
        });
      }
      this.requestCompanyData();
      this.requestStationData();
      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'operationTime',
            label: '开始充电 ',
            placeholder: '请选择充电时间',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'plateNumber',
            label: '车牌号码',
            placeholder: '请输入车牌号码',
          },
          {
            comName: 'YkcInput',
            key: 'zdlTradeSeq',
            label: '互联单号',
            placeholder: '请输入互联单号',
          },
          {
            comName: 'YkcInput',
            key: 'tradeSeq',
            label: '充电单号',
            placeholder: '请输入充电单号',
          },
          {
            comName: 'YkcInput',
            key: 'cardNo',
            label: '充电卡号',
            placeholder: '请输入充电卡号',
          },
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'userCompanyId',
            label: '归属机构',
            placeholder: '请输入归属机构',
            data: this.userCompanyIdData,
          },
          {
            remote: true,
            comName: 'YkcDropdown',
            key: 'stationId',
            label: '电站名称',
            placeholder: '请输入电站名称',
            data: this.stationIdData,
          },
          {
            label: '异常原因',
            key: 'orderAbnormalType',
            comName: 'YkcDropdown',
            placeholder: '请选择异常原因',
            data: localGetItem('dictionary')?.abnormal_type,
            configSet: {
              label: 'name',
              value: 'code',
            },
          },
          // {
          //   clearable: true,
          //   comName: 'YkcDropdown',
          //   key: 'chargingSource',
          //   label: '启动来源',
          //   placeholder: '请选择启动来源',
          //   data: [
          //     { id: '1', name: '扫码启动' },
          //     { id: '3', name: '刷卡充电' },
          //     { id: '4', name: '即插即充' },
          //     { id: '5', name: '流量方' },
          //   ],
          // },
          {
            comName: 'YkcDropdown',
            key: 'chargingType',
            label: '充电类型',
            placeholder: '请输入充电类型',
            data: [
              { id: 0, name: '正常充电' },
              { id: 1, name: '有序充电' },
            ],
          },
          {
            comName: 'YkcInput',
            key: 'gunCode',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
        ];
      },
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            id: '2',
            enabled: () => code('order:share:charge:exception:detail'),
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/orderManagement/privatePileShare/abnormalOrder/Detail',
                query: { recordId: row.recordId },
              });
            },
          },
        ];
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'abnormal_order',
          jsonNode: {
            ...this.searchParams,
          },
        });
      },
      /**
       * 自定义列
       * */
      customColumns() {
        this.showDialog = true;
      },
      /**
       * 组件change方法调用信息提示
       */
      checkboxChange(e) {
        this.customForm.checkOption = e;
        console.log('this.customForm+++', this.customForm);
      },
      /**
       *  弹窗确认
       * */
      beforeConfirm(done) {
        console.log('确认', this.customForm);
        this.$refs.customForm.validate(valid => {
          if (valid) {
            localSetItem('abnormalOrderCustomForm', JSON.stringify(this.customForm.checkOption));
            done();
            this.reload();
          }
        });
      },
      /**
       * 弹窗关闭
       * */
      onCLose() {
        this.showDialog = false;
      },
      /**
       * 获取归属电站数据
       */
      requestStationData() {
        barrierPage
          .findStationList({})
          .then(res => {
            console.log('获取归属电站数据+++++', res);
            this.stationIdData = res.map(item => ({
              id: item.stationId,
              name: item.stationName,
            }));
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取归属机构数据
       */
      requestCompanyData() {
        customerOrg
          .queryOrg({})
          .then(res => {
            console.log('获取归属机构数据+++++', res);
            this.userCompanyIdData = res || [];
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);
        if (searchData.operationTime && searchData.operationTime.length > 0) {
          [this.searchParams.startChargingTimeStart, this.searchParams.startChargingTimeEnd] =
            searchData.operationTime;
        }

        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },

      /**
       * 请求列表数据
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        orderPage
          .abnormalChargingOrderPage(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .directConnectionPile-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }

  ::v-deep.customForm .el-form-item,
  ::v-deep.customForm .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
</style>
